.customStyleText{
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
}

.textValue {
    font-size: 16px;
}

.customStyle{
    margin-right: 8px;
}